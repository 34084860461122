import request from '@/auth/jwt/useJwt'

export function getVcode() {
  return request.axiosIns({
    url: '/authorize/captcha/image?width=160&height=40',
    method: 'get',
  })
}

export const _ = null
